<template>
  <a-modal
    :visible="visible"
    :mask-closable="false"
    :width="700"
    title="配置权限"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
  >
    <a-tabs v-model:active-key="activeKey">
      <a-tab-pane key="menu" tab="菜单权限">
        <a-table
          row-key="id"
          size="middle"
          :columns="menuColumns"
          :data-source="menuList"
          :row-selection="menuRowSelection"
          :pagination="false"
          :scroll="{ y: 450 }"
        >
          <template #isEnable="{ text }">
            <a-tag :color="text ? 'success' : 'error'">
              {{ text ? '启用' : '禁用' }}
            </a-tag>
          </template>
          <template #resources="{ text }">
            <a-spin :spinning="spinning === text">
              <div v-if="menuResources[text]" class="iss-checkbox">
                <a-checkbox
                  v-for="item in menuResources[text]"
                  :key="item.id"
                  :checked="selectedMenuResources.includes(item.id)"
                  @change="handleChangeByMenu($event, item.id)"
                >
                  {{ item.name }}
                </a-checkbox>
              </div>
              <a v-else @click="hanldeClickByMenu(text)">点击获取资源</a>
            </a-spin>
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="data" tab="数据权限">
        <a-table
          row-key="dataId"
          size="middle"
          :columns="dataColumns"
          :data-source="dataList"
          :pagination="false"
        >
          <template #allOrgScope="{ record }">
            <a-checkbox
              v-model:checked="record.allOrgScope"
              @change="handleChangeByData($event, record, true)"
            />
          </template>
          <template #thisLevelOrgScope="{ record }">
            <a-checkbox
              v-model:checked="record.thisLevelOrgScope"
              @change="handleChangeByData($event, record)"
            />
          </template>
          <template #subLevelOrgScope="{ record }">
            <a-checkbox
              v-model:checked="record.subLevelOrgScope"
              @change="handleChangeByData($event, record)"
            />
          </template>
          <template #selfScope="{ record }">
            <a-checkbox
              v-model:checked="record.selfScope"
              @change="handleChangeByData($event, record)"
            />
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="field" tab="字段权限">
        <a-table
          row-key="id"
          size="middle"
          :columns="fieldColumns"
          :data-source="fieldList"
          :pagination="false"
        >
          <template #display="{ record }">
            <a-radio
              :checked="record.fieldRule === 1"
              @click="record.fieldRule = 1"
            />
          </template>
          <template #desensitization="{ record }">
            <a-radio
              :checked="record.fieldRule === 2"
              @click="record.fieldRule = 2"
            />
          </template>
          <template #hide="{ record }">
            <a-radio
              :checked="record.fieldRule === 3"
              @click="record.fieldRule = 3"
            />
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="app" tab="子应用权限">
        <a-table
          row-key="id"
          size="middle"
          :columns="appColumns"
          :data-source="appList"
          :row-selection="appRowSelection"
          :pagination="false"
          :scroll="{ y: 450 }"
        >
          <template #status="{ text }">
            <a-tag :color="text ? 'success' : 'error'">
              {{ text ? '启用' : '禁用' }}
            </a-tag>
          </template>
          <template #resources="{ text }">
            <a-spin :spinning="spinning === text">
              <a-checkbox-group
                v-if="appResources[text]"
                v-model:value="selectedAppResources[text]"
                class="iss-checkbox"
              >
                <a-checkbox
                  v-for="item in appResources[text]"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-checkbox>
              </a-checkbox-group>
              <a v-else @click="hanldeClickByApp(text)">点击获取资源</a>
            </a-spin>
          </template>
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import { computed, reactive, toRaw, toRefs, watch } from 'vue';
import {
  Checkbox,
  message,
  Radio,
  Spin,
  Table,
  Tabs,
  Tag,
} from 'ant-design-vue';
import menuApi from '@/api/menu';
import resourcesApi from '@/api/resources';
import dataApi from '@/api/data';
import fieldApi from '@/api/field';
import appApi from '@/api/app';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ATable: Table,
    ATag: Tag,
    ASpin: Spin,
    ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group,
    ARadio: Radio,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    let cache = { dataList: '', fieldList: '' };
    const state = reactive({
      activeKey: 'menu',
      menuList: [],
      menuResources: {},
      selectedMenu: [],
      selectedMenuResources: [],
      spinning: false,
      dataList: [],
      fieldList: [],
      appList: [],
      appResources: {},
      selectedApp: [],
      selectedAppResources: {},
    });
    const menuRowSelection = computed(() => ({
      selectedRowKeys: state.selectedMenu,
      onChange: selectedRowKeys => {
        state.selectedMenu = selectedRowKeys;
      },
    }));
    const appRowSelection = computed(() => ({
      selectedRowKeys: state.selectedApp,
      onChange: selectedRowKeys => {
        state.selectedApp = selectedRowKeys;
      },
    }));
    const handleCancel = () => context.emit('update:visible', false);
    // 菜单列表接口
    menuApi.getList('role:config').then(data => {
      console.log('data111', data)
      state.menuList = data;
    });
    // 数据权限接口
    dataApi.getList('role:config').then(data => {
      cache.dataList = JSON.stringify(
        data.map(({ id, name }) => ({ dataId: id, dataName: name }))
      );

    });
    fieldApi.getList('role:config').then(data => {
      cache.fieldList = JSON.stringify(data);
    });
    // 子应用接口
    appApi.getList('role:config').then(data => {
      state.appList = data;
    });

    watch(
      () => props.visible,
      visible => {
        if (visible) {
          const id = props.initValue.id;
          state.activeKey = 'menu';
          // 菜单权限列表接口
          menuApi.getListByRoleId('role:config', id).then(data => {
            console.log('data', data)
            state.selectedMenu = data.menuIdList;
            state.selectedMenuResources = data.resourceIdList;
          });
          dataApi.getListByRoleId('role:config', id).then(data => {
            let temp = JSON.parse(cache.dataList);
            data?.length &&
              temp.forEach(item => {
                const i = data.find(d => d.dataId === item.dataId);
                i && Object.assign(item, i);
              });
            state.dataList = temp;
          });
          fieldApi.getListByRoleId('role:config', id).then(data => {
            const temp = JSON.parse(cache.fieldList);
            data?.length &&
              temp.forEach(item => {
                const i = data.find(d => d.fieldName === item.fieldName);
                i && Object.assign(item, { fieldRule: i.fieldRule });
              });
            state.fieldList = temp;
          });
          appApi.getListByRoleId('role:config', id).then(data => {
            state.selectedApp = data;
          });
        }
      }
    );

    return {
      menuColumns: [
        { dataIndex: 'name', title: '名称', width: 220 },
        {
          dataIndex: 'isEnable',
          title: '状态',
          width: 100,
          slots: { customRender: 'isEnable' },
        },
        {
          dataIndex: 'id',
          title: '资源',
          slots: { customRender: 'resources' },
        },
      ],
      dataColumns: [
        { dataIndex: 'dataName', title: '名称' },
        {
          dataIndex: 'allOrgScope',
          title: '全部',
          slots: { customRender: 'allOrgScope' },
        },
        {
          dataIndex: 'thisLevelOrgScope',
          title: '本部门',
          slots: { customRender: 'thisLevelOrgScope' },
        },
        {
          dataIndex: 'subLevelOrgScope',
          title: '下级部门',
          slots: { customRender: 'subLevelOrgScope' },
        },
        {
          dataIndex: 'selfScope',
          title: '本人',
          slots: { customRender: 'selfScope' },
        },
      ],
      fieldColumns: [
        { dataIndex: 'fieldName', title: '名称' },
        { dataIndex: 'remark', title: '描述' },
        {
          dataIndex: 'fieldRule',
          title: '显示',
          slots: { customRender: 'display' },
        },
        {
          dataIndex: 'fieldRule',
          title: '脱敏',
          slots: { customRender: 'desensitization' },
        },
        {
          dataIndex: 'fieldRule',
          title: '隐藏',
          slots: { customRender: 'hide' },
        },
      ],
      appColumns: [
        { dataIndex: 'name', title: '名称', width: 220 },
        {
          dataIndex: 'status',
          title: '状态',
          width: 100,
          slots: { customRender: 'status' },
        },
        {
          dataIndex: 'id',
          title: '资源',
          slots: { customRender: 'resources' },
        },
      ],
      menuRowSelection,
      appRowSelection,
      ...toRefs(state),
      handleCancel,
      // 保存方法
      handleOk: () => {
        const roleId = props.initValue.id;
        let result;
        switch (state.activeKey) {
          case 'menu':
            result = menuApi.saveListToRole('role:config', {
              roleId,
              menuIdList: toRaw(state.selectedMenu),
              resourceIdList: toRaw(state.selectedMenuResources),
            });
            break;
          case 'data':
            result = dataApi.saveListToRole('role:config', {
              roleId,
              dataScopeList: toRaw(state.dataList),
            });
            break;
          case 'field':
            result = fieldApi.saveListToRole('role:config', {
              roleId,
              fieldRuleList: toRaw(state.fieldList),
            });
            break;
          case 'app':
            result = appApi.saveListToRole('role:config', {
              roleId,
              appClientIdList: toRaw(state.selectedApp),
            });
            break;
          default:
            break;
        }
        result &&
          result.then(() => {
            message.success('操作成功');
            handleCancel();
          });
      },
      hanldeClickByMenu: menuId => {
        state.spinning = menuId;
        resourcesApi
          .getPage('role:config', { current: 1, size: 10000, menuId })
          .then(({ records }) => {
            state.menuResources[menuId] = records;
          })
          .finally(() => {
            state.spinning = false;
          });
      },
      hanldeClickByApp: () => {},
      handleChangeByMenu: ({ target }, id) => {
        if (target.checked) {
          state.selectedMenuResources.push(id);
        } else {
          state.selectedMenuResources.splice(
            state.selectedMenuResources.findIndex(item => item === id),
            1
          );
        }
      },
      handleChangeByData: ({ target }, item, isAll) => {
        if (target.checked && isAll) {
          item.thisLevelOrgScope = true;
          item.subLevelOrgScope = true;
          item.selfScope = true;
        } else {
          item.allOrgScope = false;
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-checkbox .ant-checkbox-wrapper {
  margin-right: 8px;
}
.iss-checkbox .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
</style>
