'use strict';

import http from '@/utils/http';

export default {
  getList(code, params) {
    return http.get('/authority/field/list', { params, code });
  },
  getListByRoleId(code, roleId) {
    return http.get(`/authority/fieldRule/authority/${roleId}`, { code });
  },
  add(code, data) {
    return http.post('/authority/field', data, { code });
  },
  update(code, data) {
    return http.put('/authority/field', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/field', { params, code });
  },
  saveListToRole(code, data) {
    return http.post('/authority/fieldRule/authority', data, { code });
  },
  // 获取字段数据
  getFieldList(code, params) {
    return http.get('/form/baseField/getList', { params, code }).then(data => {
      // 字段分类 1:基础字段 2:通用字段
      let temp = [
        { id: 'base', group: '基础字段', children: [] },
        { id: 'currency', group: '通用字段', children: [] },
      ];
      data.forEach(item => temp[item.category - 1].children.push(item));
      return temp;
    });
  },
};
