'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/authority/resource/page',
  getPage(code, params) {
    return http.get('/authority/resource/page', { params, code });
  },
  add(code, data) {
    return http.post('/authority/resource', data, { code });
  },
  update(code, data) {
    return http.put('/authority/resource', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/resource', { params, code });
  },
};
