'use strict';

import http from '@/utils/http';

export default {
  getList(code, params) {
    return http.get('/authority/menu/tree', { params, code });
  },
  getListByRoleId(code, roleId) {
    return http.get(`/authority/role/authority/${roleId}`, { code });
  },
  add(code, data) {
    return http.post('/authority/menu', data, { code });
  },
  update(code, data) {
    return http.put('/authority/menu', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/menu', { params, code });
  },
  saveListToRole(code, data) {
    return http.post('/authority/role/authority', data, { code });
  },
};
